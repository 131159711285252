import Swiper, { Navigation, Pagination } from "swiper";
// import Combobo from "combobo";
import SimpleBar from "simplebar";
import simpleParallax from "simple-parallax-js";
import ClipboardJS from "clipboard";

export default {
  init() {
    console.log('CD is working!')
    // what to run on load / resize
    window.addEventListener( "load", ()=> {
      menuFunctions();
      rearrangeFooter();
      buttonBorderRadius();
      flipTxtImg();
      parallaxCircleImage();
    } );

    window.addEventListener( "resize", e => {
      setTimeout( () => {
        menuFunctions();
   
        rearrangeFooter();
        buttonBorderRadius();
        flipTxtImg();
        parallaxCircleImage();
      }, 200 );
    } );

    // media queries
    let mobile = window.matchMedia( "(max-width: 1199px)" );
    let desktop = window.matchMedia( "(min-width: 1200px)" );

    // check for Safari 3.0+ "[object HTMLElementConstructor]"
    const isSafari =
     /constructor/i.test( window.HTMLElement ) ||
     ( function( p ) {
       return p.toString() === "[object SafariRemoteNotification]";
     } )(
       !window["safari"] ||
         ( typeof safari !== "undefined" && safari.pushNotification )
     );
    if ( isSafari == true ) {
      $( "html" ).addClass( "safari" );
    }

    // cookie banner close button
    let cookieBannerClose = () => {
      let cookieBanner = $( ".cookie-banner" );
      
      if ( $( cookieBanner ).length ) {
        let closeCookieBanner = $( "#close-cookie-banner" );
        closeCookieBanner.on( "click", function() {
          cookieBanner.removeClass( "visible" );
        } );
      }
    };
    cookieBannerClose();

    /* BEGIN SKIP LINKS */
    // skip link scroll to section
    const skipToAnchor = aid => {
      var aTag = $( aid );
      var focus = true;
      $( "html,body" ).animate(
        {
          scrollTop: aTag.offset().top
        },
        "slow"
      );
      var first_child = $( aTag.children()[0] );
      var tag = first_child.prop( "tagName" ).toLowerCase();

      if ( tag !== "a" && tag !== "button" && tag !== "input" && tag !== "textarea" ) {
        if ( first_child.attr( "tabIndex" ) !== undefined ) {
          first_child.attr( "tabIndex", -1 ).focus();
          first_child.removeAttr( "tabIndex" );
        }
        else {
          first_child.focus();
        }
      }
      else {
        first_child.focus();
      }
    };

    // create skip links
    const skipLinks = () => {
      $( "section" ).each( function() {
        const id = $( this ).attr( "id" );
        if ( id !== undefined ) {
          // Use the section id to create a label for the items in the skip link list
          var sectionNameArray = id.split( "-" );
          var sectionName = "";
          for ( var i = 0; i < sectionNameArray.length; i++ ) {
            var str = sectionNameArray[i];
            str = str.toLowerCase().replace( /\b[a-z]/g, function( letter ) {
              return letter.toUpperCase();
            } );
            sectionName += str;
            if ( i < sectionNameArray.length - 1 ) {
              sectionName += " ";
            }
          }
          var skiplink =
                        "<li><a href='#" +
                        id +
                        "' class='text-link'>Skip to " +
                        sectionName +
                        "</a></li>";
          $( ".skiplinks ul" ).append( skiplink );
        }
      } );

      const skipLinkContainer = $( ".skiplinks" ),
        skipLink = $( ".skiplinks a" );

      skipLink.on( "focus", function() {
        skipLinkContainer.addClass( "show" );
      } );

      skipLink.on( "blur", function() {
        skipLinkContainer.removeClass( "show" );
      } );

      skipLink.on( "click", function( e ) {
        e.preventDefault();
        skipToAnchor( $( this ).attr( "href" ) );
      } );
    };

    skipLinks();

    // Scroll To
    ( function() {
      $( ".scrollto" ).on( "click", function( e ) {
        e.preventDefault();
        var section = $( this ).attr( "href" );
        $( "html, body" ).animate( { scrollTop: $( section ).offset().top }, 1000 );
      } );
    } )();
    /* END SKIP LINKS */


    // add active on geo translate
    ( function() {
      $( ".glink" ).on( "click", function( e ) {
         $('.glink').removeClass('active');
         $(this).addClass('active');
      } );
    } )();
    /* END SKIP LINKS */

    let menuFunctions = () => {
      // menu functions
      let menuDiv = document.querySelector( ".header.header .menu" );
      let header = document.querySelector( "header.header" );
      let hamburger = document.querySelector( "button.menu-toggle" );
      let html = document.querySelector( "html" );
      let mainMenuItems = document.querySelectorAll( "li.dropdown > button" );
      let langIcon = document.querySelector( "button.lang-toggle" );
      let langOptions = document.querySelectorAll( ".icons-indiv.lang > .dropdown ul li a" );
      let langLastLang = langOptions[langOptions.length - 1];
      let langFirstLang = langOptions[0];

      // menu toggle button
      let menuToggle = () => {
        if ( mobile.matches ) {
			if(hamburger.getAttribute('listener') !== 'true') {
			   hamburger.setAttribute('listener', 'true');	
			  hamburger.addEventListener( "click", ()=> {
				header.classList.toggle( "mobile-open" );
				html.classList.toggle( "no-scroll" );
				// collapse everything when you close menu
				mainMenuItems.forEach( item=> {
				  item.classList.remove( "hovered" );
				} );
			  } );
			}
        }
        else {
          header.classList.remove( "mobile-open" );
          // collapse everything when you close menu
          mainMenuItems.forEach( item=> {
            item.classList.remove( "hovered" );
          } );
        }
      };
      menuToggle();

      let menuIcons = () => {
        let icons = document.querySelector( ".icons" );
        let topDiv = document.querySelector( "header.header .top" );
       
        if ( mobile.matches ) {
        // move it inside the menu div
          menuDiv.appendChild( icons );
        }
        else {
        // move it inside the top div
          topDiv.appendChild( icons );
        }
      };
      menuIcons();

      // submenu toggle
      let submenuToggle = () => {
        let mainMenuItems = $( "li.dropdown > button" );
        let langIcon = $( ".icons-indiv.lang > button" );
        let icons = $( ".icons-indiv > a" );
      
        if ( mobile.matches ) {
        // clear out desktop menu stuff
          mainMenuItems.off();
          langIcon.off();
          mainMenuItems.removeClass( "hovered" );
          langIcon.removeClass( "hovered" );

          // toggle submenu links
          mainMenuItems.on( "click", e => {
            e.stopImmediatePropagation();
            const eTarget = e.target;
            const togClass = "hovered";

            langIcon.removeClass( "hovered" );
        
            // close submenu if you open a new one
            if ( eTarget.closest( "button" ).classList.contains( togClass ) ) {
              eTarget.closest( "button" ).classList.remove( togClass );
            }
            else {
              mainMenuItems.each( function() {
                this.classList.remove( togClass );
              } );
              eTarget.closest( "button" ).classList.add( togClass );
            }
          } );
										
					const submenuLink = $('.submenu-link');
					submenuLink.on( "click", e=> {
						e.stopImmediatePropagation();					
						header.classList.remove( "mobile-open" );
            html.classList.remove( "no-scroll" );						
					})

          // remove focus from langIcon if you click a different button
          icons.on( "click", e=> {
            e.stopImmediatePropagation();
            if ( e.target !== langIcon ) {
              // remove the language focus class unless you click on the lang icon
              langIcon.removeClass( "hovered" );
              // close other submenus
              mainMenuItems.removeClass( "hovered" );
            }
          } );

          langIcon.on( "click", e => {
            e.stopImmediatePropagation();
            const eTarget = e.target;
            const togClass = "hovered";

            mainMenuItems.removeClass( "hovered" );
          
            // close submenu if you open a new one
            if ( eTarget.closest( "button" ).classList.contains( togClass ) ) {
              eTarget.closest( "button" ).classList.remove( togClass );
              eTarget.closest( "button" ).classList.remove( "focused" );
            }
            else {
              langIcon.removeClass( togClass );
              eTarget.closest( "button" ).classList.add( togClass );
            }
          } );
        }
        else if ( desktop.matches ) {
        // clear out mobile menu stuff
          mainMenuItems.off();
          langIcon.off();
          mainMenuItems.removeClass( "hovered" );
          langIcon.removeClass( "hovered" );
          let mobileMenu = $( ".header-mobile" );
          mobileMenu.removeClass( "open" );
          $( "html" ).removeClass( "no-scroll" );

          mainMenuItems.on( "mouseenter", function() {
            $( this ).addClass( "hovered" );
          } );
          mainMenuItems.on( "mouseleave", function() {
            $( this ).removeClass( "hovered" );
          } );

          langIcon.on( "mouseenter", function() {
            $( this ).addClass( "hovered" );
          } );
          langIcon.on( "mouseleave", function() {
            $( this ).removeClass( "hovered" );
          } );

          // add focus state to lang icon
          const accessibleLangHover = e => {
            let langIcon = document.querySelector( "header.header .lang-toggle" );
            langIcon.addEventListener( "keydown", e=> {
              if ( e.keyCode === 13 ) {
                if ( e.target.classList.contains( "lang-toggle" ) ) {
                  e.target.classList.toggle( "focused" );
                }
              }
              else if ( e.shiftKey && e.keyCode === 9 ) {
                if ( e.target.classList.contains( "lang-toggle" ) ) {
                  e.target.classList.remove( "focused" );
                }
              }
            } );
            // eslint-disable-next-line max-len
			if(langIcon.nextElementSibling.getElementsByTagName( "ul" )[0]){
				let lastSubmenuLink = langIcon.nextElementSibling.getElementsByTagName( "ul" )[0].lastElementChild.firstElementChild;
				lastSubmenuLink.addEventListener( "keydown", e=> {
				  if ( e.keyCode === 9 ) {
					langIcon.classList.remove( "focused" );
				  }
				} );
			}
          };
          accessibleLangHover();

          // add a11y to hover / focus
          const accessibleHover = e => {
            if ( e.keyCode === 13 ) {
              if ( e.target.classList.contains( "dropdown-link" ) ) {
                e.target.classList.toggle( "focused" );
              }
            }
            else if ( e.shiftKey && e.keyCode === 9 ) {
              if ( e.target.classList.contains( "dropdown-link" ) ) {
                e.target.classList.remove( "focused" );
              }
            }
          };

          const closeDropdown = e => {
          // close dropdown if tabbing to prev parent link, or next parent link
         
            const linkType = document.getElementsByClassName( "submenu-link" );
            for ( let i = 0; i < linkType.length; ++i ) {
              linkType[i].addEventListener( "focusout", e => {
                if ( e.relatedTarget ) {
                  if ( !e.relatedTarget.classList.contains( "submenu-link" ) &&
                ( !e.relatedTarget.classList.contains( "btn" ) ) ) {
                    const submenu = e.target.closest( ".submenu" );
                    const parentLink = submenu.previousElementSibling;
                    parentLink.classList.remove( "focused" );
                  }
                }
              } );
            }
          };
          closeDropdown();

          $( mainMenuItems ).each( function( index, item ) {
            const subMenuContent = item.nextElementSibling;
         
            item.addEventListener( "keydown", accessibleHover );

            const lastSubMenuLink = subMenuContent.lastElementChild;

            lastSubMenuLink.addEventListener( "blur", closeDropdown );
          } );
        }
      };
      submenuToggle();

      // trap focus
      let trapFocus = () => {
        // language icon is last
		if(langIcon){
			langIcon.addEventListener( "keydown", e => {
			  if ( header.classList.contains( "mobile-open" ) ) {
				// don't do anything special if going back
				if ( e.shiftKey && e.keyCode === 9 ) {
				  return null;
				}
				// go to menu toggle
				else if ( e.keyCode === 9 ) {
				  e.preventDefault();
				  if ( langIcon.classList.contains( "focused" ) || langIcon.classList.contains( "hovered" ) ) {
					langIcon.blur();
					langFirstLang.focus();
				  }
				  else {
					langIcon.blur();
					hamburger.focus();
				  }
				}
			  }
			} );
		}
        // if the lang submenu is open, loop to the hamburger
		if(langLastLang){
			langLastLang.addEventListener( "keydown", e => {
			  if ( header.classList.contains( "mobile-open" ) ) {
				// don't do anything special if going back
				if ( e.shiftKey && e.keyCode === 9 ) {
				  return null;
				}
				// go to menu toggle
				else if ( e.keyCode === 9 ) {
				  e.preventDefault();
				  langLastLang.blur();
				  langIcon.classList.remove( "hovered" );
				  hamburger.focus();
				}
			  }
			} );
		}

		if(langFirstLang){
			langFirstLang.addEventListener( "keydown", e => {
			  if ( header.classList.contains( "mobile-open" ) ) {
				if ( e.shiftKey && e.keyCode === 9 ) {
				  e.preventDefault();
				  langFirstLang.blur();
				  langIcon.classList.remove( "hovered" );
				  langIcon.focus();
				}
			  }
			} );
		}
		
        // exit button is first
        hamburger.addEventListener( "keydown", e => {
          // got to language icon
          if ( e.shiftKey && e.keyCode === 9 ) {
            e.preventDefault();
            hamburger.blur();
            langIcon.focus();
          }
          // don't do anything special if going forward
          else if ( e.keyCode === 9 ) {
            return null;
          }
        } );
      };
      trapFocus();
    };

    // smooth scrolling
    let smoothScroll = () => {
      //Smooth scrolling with anchor links
      $( document ).on( "click", "a[href^=\"#\"]", function( event ) {
        event.preventDefault();
    
        $( "html, body" ).animate( {
          scrollTop: $( $.attr( this, "href" ) ).offset().top
        }, 500 );
      } );
    };
    smoothScroll();
    // Rearrange footer based on size
    let rearrangeFooter = () => {
      let bottomLinks = $( "footer .bottom-links" );
      let socials = $( "footer .socials" );
      if ( mobile.matches ) {
        bottomLinks.insertAfter( socials );
      }
      else {
        socials.insertAfter( bottomLinks );
      }
    };

    let printPage = () => {
      let printButton = document.getElementById( "print-page" );
      if ( printButton ) {
        printButton.addEventListener( "click", ()=> {
          window.print();
        } );
      }
    };
    printPage();

    // if button is two lines, round border more
    let buttonBorderRadius = () => {
      let buttons = $( "main .btn" );
      if ( $( buttons ).length ) {
        buttons.each( function() {
          // check if buttons are above default height
          if ( $( this ).height() > 40 ) {
            $( this ).addClass( "two-lines" );
          }
          else {
            $( this ).removeClass( "two-lines" );
          }
        } );
      }
    };

    // latest news slider
    let latestNews = ()=> {
      let latestNewsSections = document.querySelectorAll( ".latest-news" );
      // if latest news exist
      if ( latestNewsSections.length ) {
        // add different class to each slider so you can have multiple
        for ( let i = 0; i < latestNewsSections.length; i++ ) {
          latestNewsSections[i].classList.add( `slider-${i + 1}` );
        }

        latestNewsSections.forEach( slider=> {
          let swiperId = slider.classList[1];
          let swiper = new Swiper( `.${swiperId} .swiper-container`, {
            // Optional parameters
            direction: "horizontal",
            loop:      false,

            // If we need pagination
            pagination: {
              el:        `.${swiperId} .swiper-pagination`,
              clickable: true
            },

            // Navigation arrows
            navigation: {
              nextEl: `.${swiperId} .swiper-button-next`,
              prevEl: `.${swiperId} .swiper-button-prev`
            }
            
          } );
        } );
      }
    };

    latestNews();

    // Image gallery slider
    let imageGallery = ()=> {
      let imageGalleryModule = $( ".image-gallery" );
      // if latest news exist
      if ( imageGalleryModule.length ) {
        // add multiple swipers if necessary
        let imageGalleries = document.querySelectorAll( ".image-gallery" );
        // add different class to each slider so you can have multiple
        for ( let i = 0; i < imageGalleries.length; i++ ) {
          imageGalleries[i].classList.add( `gallery-${i + 1}` );
        }

        imageGalleries.forEach( gallery=> {
          let swiperId = gallery.classList[1];
          let swiper = new Swiper( `.${swiperId} .swiper-container`, {
            // Optional parameters
            direction:      "horizontal",
            loop:           false,
            spaceBetween:   280,
            slidesPerView:  "auto",
            centeredSlides: true,
           
            initialSlide: 0,
            speed:        400,
            autoHeight:   false,

            effect:          "coverflow",
            coverflowEffect: {
              rotate:       0,
              stretch:      0,
              depth:        430,
              modifier:     1,
              slideShadows: false
            },
            
            // If we need pagination
            pagination: {
              el:        `.${swiperId} .swiper-pagination`,
              clickable: true
            },
  
            // Navigation arrows
            navigation: {
              nextEl: `.${swiperId} .swiper-button-next`,
              prevEl: `.${swiperId} .swiper-button-prev`
            }
          } );
        } );
      }
    };
  
    imageGallery();

    // faq module functions
    let faqModule = () => {
      let faqModule = document.querySelectorAll( ".faq-module" );
      
      //$( ".faq-module" );
      
      // if faq module exists
      if ( faqModule.length ) {
        let questionToggle = document.querySelectorAll( ".faq-module .question-indiv button.header" );
        let shareQuestion = document.querySelectorAll( ".faq-module .question-indiv .content .share button" );

        // check if using anchor link
        if ( window.location.hash ) {
          let hashId = ( window.location.hash ).replace( "#", "" );
        
          // open question linked to
          questionToggle.forEach( ( toggle=> {
            let toggleId = toggle.parentElement.previousElementSibling.id;
            let toggleHeader = toggle.parentElement;
          
            if ( toggleId === hashId ) {
              let firstQuestion = questionToggle[0].parentElement;
              firstQuestion.classList.remove( "open" );
              toggleHeader.classList.add( "open" );
            }
          } ) );
        }
        else {
          // if no anchor link, open the first question by default
         
        }

        questionToggle.forEach( toggle=> {
          toggle.addEventListener( "click", ()=> {
            toggle.parentElement.classList.toggle( "open" );
          } );
        } );

        shareQuestion.forEach( question=> {
          // add tooltip on hover
          question.addEventListener( "mouseenter", e=> {
            let currentQuestion = e.currentTarget;
            let clickToCopy = currentQuestion.nextElementSibling;
            clickToCopy.classList.add( "visible" );
          } );
          // remove tooltip when done hovering
          question.addEventListener( "mouseleave", e=> {
            let currentQuestion = e.currentTarget;
            let clickToCopy = currentQuestion.nextElementSibling;
            clickToCopy.classList.remove( "visible" );
          } );
          // copy link
          question.addEventListener( "click", e=> {
            e.preventDefault;
           
            let currentQuestion = e.currentTarget;
            let clickToCopy = currentQuestion.nextElementSibling;
            clickToCopy.classList.remove( "visible" );
    
            /**
            * execCommand is listed as depreciated with no indication of alternative,
            * so using ClipboardJS in the meantime
            * @param data-clipboard-target
            * @uses ClipboardJS
            * @link https://github.com/zenorocha/clipboard.js
            */
            // new ClipboardJS(question);
            
            // copy the link
            // eslint-disable-next-line max-len
            // let anchorId = currentQuestion.parentElement.parentElement.parentElement.previousElementSibling.id;
            // let anchorLink = `${window.location.origin}${window.location.pathname}#${anchorId}`;
            //
            // let tempInput = document.createElement( "input" );
            // tempInput.type = "text";
            // tempInput.value = anchorLink;
            //
            // document.body.appendChild( tempInput );
            //
            // tempInput.select();
            // document.execCommand( "Copy" );
            //
            // document.body.removeChild( tempInput );

            // show the tooltip telling user link copied
            let linkCopied = clickToCopy.nextElementSibling;
            linkCopied.classList.add( "visible" );
            // remove tooltip after 2 seconds
            setTimeout( () => {
              linkCopied.classList.remove( "visible" );
            }, 2000 );
          } );
        } );
      }
    };
    faqModule();
    
    /**
     * execCommand is listed as depreciated with no indication of alternative,
     * so using ClipboardJS in the meantime
     * @param data-clipboard-text
     * @uses ClipboardJS
     * @link https://github.com/zenorocha/clipboard.js
     */
    //const socialShareLink = () => new ClipboardJS(document.querySelectorAll('.clipboard-target'));
    //socialShareLink();

    var clipboardDemos = new ClipboardJS('.clipboard-target')
      clipboardDemos.on('success', function (e) {
          e.clearSelection()
          showTooltip()
      });
      function showTooltip(){
        $('.tool').addClass('visible');
        setTimeout(function () {
            $('.tool').removeClass('visible');
        }, 2000)
      }

      $('body').on('hover', '.clipboard-target', function () {
        $('.copy-link').addClass('visible');
      }, function(){
          $('.copy-link').removeClass('visible');
      })

    // positions module toggle open / closed
    let positionsModule = () => {
      let positionsModule = document.querySelectorAll( ".positions-module" );
     
      if ( positionsModule.length ) {
        let positionToggle = document.querySelectorAll( ".positions-module .position-indiv button.header" );
        
        positionToggle.forEach( toggle=> {
          toggle.addEventListener( "click", ()=> {
            toggle.parentElement.classList.toggle( "open" );
          } );
        } );
      }
    };
    positionsModule();

    // flips two col layouts for mobile + back
    let flipTxtImg = () => {
      //  two col with image
      let txtImgFlipped = document.querySelectorAll( ".txt-img-two-col .wrapper.inner.flipped" );
      if ( txtImgFlipped.length ) {
        txtImgFlipped.forEach( flippedSection=> {
          let left = flippedSection.querySelector( ".left" );
          let right = flippedSection.querySelector( ".right" );
          if ( mobile.matches ) {
            // origElement.parentNode.insertBefore(movedElement, origElement)
            left.parentNode.insertBefore( right, left );
          }
          else {
            // origElement.parentNode.insertBefore(movedElement, origElement)
            right.parentNode.insertBefore( left, right );
          }
        } );
      }

      // two col, no image, with featured points
      let txtFtFlipped = document.querySelectorAll( ".txt-ft-two-col.flipped" );
      if ( txtFtFlipped.length ) {
        txtFtFlipped.forEach( flippedSection=> {
          let left = flippedSection.querySelector( ".left" );
          let right = flippedSection.querySelector( ".right" );
          if ( mobile.matches ) {
            // origElement.parentNode.insertBefore(movedElement, origElement)
            left.parentNode.insertBefore( right, left );
          }
          else {
            // origElement.parentNode.insertBefore(movedElement, origElement)
            right.parentNode.insertBefore( left, right );
          }
        } );
      }
    };

    // History slider
    let historySlider = ()=> {
      // if history module exist
      let historySliders = document.querySelectorAll( ".history-slider" );
      if ( historySliders.length ) {
        // add different class to each slider so you can have multiple
        for ( let i = 0; i < historySliders.length; i++ ) {
          historySliders[i].classList.add( `slider-${i + 1}` );
        }
        
        // add multiple swipers if necessary
        historySliders.forEach( slider=> {
          let defaultImg = slider.querySelector( ".image img" );
          
          let swiperId = slider.classList[1];
          
          let swiper = new Swiper( `.${swiperId} .swiper-container`, {
            // Optional parameters
            direction:    "horizontal",
            loop:         false,
            spaceBetween: 100,

            // If we need pagination
            pagination: {
              el:        `.${swiperId} .swiper-pagination`,
              clickable: true
            },

            // Navigation arrows
            navigation: {
              nextEl: `.${swiperId} .swiper-button-next`,
              prevEl: `.${swiperId} .swiper-button-prev`
            }
            
          } );

          // when slide changes, add correct image in
          swiper.on( "slideChange", ()=> {
            let indivSlides = slider.querySelectorAll( ".swiper-slide" );
            let currentSlide = indivSlides[swiper.activeIndex];
            
            let currentImg = currentSlide.getAttribute( "data-img" );
            let currentAlt = currentSlide.getAttribute( "data-alt" );
            defaultImg.classList.add( "fade-out" );
            setTimeout( () => {
              defaultImg.src = currentImg;
              defaultImg.alt = currentAlt;
              defaultImg.classList.add( "fade-in" );
            }, 300 );
            setTimeout( () => {
              defaultImg.classList.remove( "fade-out", "fade-in" );
            }, 600 );
            // indivSlides.forEach( slide=> {
            //   let slideImg = slide.getAttribute( "data-img" );
            //   let slideAlt = slide.getAttribute( "data-alt" );
            // } );
          } );
        } );
      }
    };
    
    historySlider();

    // dyanmic two column lists using grid

    let dynamicTwoColLists = () => {
      let twoColLists = document.querySelectorAll( "ul.two-col" );
      if ( twoColLists[0] ) {
        twoColLists.forEach( list=> {
          let rowAmount = Math.round( list.childElementCount / 2 );
          let setRows = "auto ".repeat( rowAmount );
          list.style["grid-template-rows"] = setRows;
        } );
      }
    };
    dynamicTwoColLists();

    let filterSearch = () => {
      let filterSearchModule = document.querySelectorAll( ".filter-search" );
     
      // add custom class
      if ( filterSearchModule.length ) {
        for ( let i = 0; i < filterSearchModule.length; i++ ) {
          filterSearchModule[i].classList.add( `filters-${i + 1}` );
        }
      }

      filterSearchModule.forEach( filter=> {
        let filterId = filter.classList[1];
        
        // Combobo moved to FilterBar.vue setupCombo method and clearFilters method

        // clear search
        let clearSearch = document.querySelector( `.${filterId} #clear-search` );
        let search = document.querySelector( `.${filterId} #search` );
        clearSearch.addEventListener( "click", ()=> {
          search.value = "";
        } );

        // open filters on mobile
        let filterInner = document.querySelector( ".filter-search .inner" );
        let expandButton = document.querySelector( `.${filterId} #expand-filters` );
     
        expandButton.addEventListener( "click", ()=> {
          filterInner.classList.toggle( "expanded" );
          if ( filterInner.classList.contains( "expanded" ) ) {
            expandButton.innerHTML = "Close Filters";
          }
          else {
            expandButton.innerHTML = "Expand Filters";
          }
        } );
      } );
    };
    filterSearch();

    // affiliates module
    let affiliatesModule = () => {
      // regular flipped
      let affiliates = document.querySelectorAll( ".affiliates" );
      if ( affiliates.length ) {
        // custom scrollbar
        
        new SimpleBar( document.getElementById( "orgs-scroll" ), { autoHide: false } );

        // open and close orgs on name click
          let body = $('body');

          body.on('click', 'button.org-btn', function(){
              var id = $(this).parent().attr('data-id');
              $('.affiliates .org-indiv').addClass('collapsed');
              $(this).parent().removeClass('collapsed');
              if (id) {
                  $('.map .image .map-image').removeClass('active');
                  $('.map .image #'+id+'').addClass('active');
              }

              return false;
          });
      }
    };
    affiliatesModule();

    // parallax on scroll for circle images
    let parallaxCircleImage = () => {
      let parallaxImages = document.querySelectorAll( ".parallax-img img" );
      let parallax = new simpleParallax( parallaxImages, {
        scale:         1.025,
        customWrapper: ".parallax-wrapper"
      } );

      if ( mobile.matches ) {
        parallax.destroy();
      }
      else {
        parallax.refresh();
      }
    };

    // reveal on scroll
    const revealElements = document.querySelectorAll( ".reveal" );

    function reveal() {
      revealElements.forEach( revealElement => {
        const revealAt = ( window.scrollY + window.innerHeight ) - revealElement.offsetHeight / 2;
        const itemPosition = window.pageYOffset + revealElement.getBoundingClientRect().top;
        const itemBottom = itemPosition + revealElement.offsetHeight;
        const isHalfShown = revealAt > itemPosition;
        const isNotScrolledPast = window.scrollY < itemBottom;
        if ( isHalfShown && isNotScrolledPast ) {
          revealElement.classList.add( "on" );
        }
        // disable if you don't want it to reanimate
        // else {
        //   revealElement.classList.remove( "on" );
        // }
      } );
    }

    window.addEventListener( "scroll", reveal );
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
  }
};
