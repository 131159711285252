/** import external dependencies */
import $ from 'jquery/dist/jquery.js';
import "jquery-validation/dist/jquery.validate";
import "waypoints/lib/jquery.waypoints.min.js";
import "prcomboselect";
import Swiper from 'swiper';
import Combobo from "combobo";
import SimpleBar from "simplebar";
import simpleParallax from "simple-parallax-js";
import "focus-visible/dist/focus-visible.min";

/** import local dependencies */
import Router from './util/Router';
import common from './routes/common';
import home from './routes/homepage'; // Used for front-page.blade.php
import pageTemplateTemplateAbout from './routes/about';
import pageTemplateTemplateFaq from "./routes/faq";
import pageTemplateTemplateMagazineLanding from "./routes/magazine-landing";
import blog from "./routes/news-events-landing";
import archive from "./routes/news-events-landing.js";
import pageTemplateTemplateProductList from "./routes/product-list";
import postTypeArchiveResources from "./routes/resources-landing";
import pageTemplateTemplateBeyondCertification from "./routes/beyond-certification";
import pageTemplateTemplateCertifiedDecertified from "./routes/certified-decertified";
import pageTemplateTemplateContact from "./routes/contact";
/**
 * Populate Router instance with DOM routes
 * @type {Router} routes - An instance of our router
 */
const routes = new Router({
	/** All pages */
	common,
	/** Home page */
	home,
	/** Posts Archive */
	blog,
    archive,
	/** Custom Page Templates */
    pageTemplateTemplateAbout,
    pageTemplateTemplateFaq,
    pageTemplateTemplateMagazineLanding,
    pageTemplateTemplateProductList,
    postTypeArchiveResources,
    pageTemplateTemplateBeyondCertification,
    pageTemplateTemplateCertifiedDecertified,
		pageTemplateTemplateContact,
});

/**
 * Polyfill Corrections useful for Vue
 */
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = function(callback, thisArg) {
        thisArg = thisArg || window;
        for (var i = 0; i < this.length; i++) {
            callback.call(thisArg, this[i], i, this);
        }
    };
}
if (window.NodeList && !NodeList.prototype.forEach) {
    NodeList.prototype.forEach = Array.prototype.forEach;
}
if (typeof NodeList.prototype.forEach !== 'function')  {
    NodeList.prototype.forEach = Array.prototype.forEach;
}

/** Load Events */
jQuery(document).ready(() => routes.loadEvents());
