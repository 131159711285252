import Combobo from "combobo";

export default {
    init() {
        const comboboYear = new Combobo({
            input: "#select-year",
            list: ".listbox.year",
            options: ".option", // qualified within `list`
            openClass: "open",
            activeClass: "active",
            selectedClass: "selected",
            useLiveRegion: false,
            multiselect: false,
            noResultsText: null,
            selectionValue: selecteds => selecteds.map(s => s.innerText.trim()).join(", "),
            // eslint-disable-next-line max-len
            optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
            filter: "contains",
            autoFilter: false
        });

        const comboboIssue = new Combobo({
            input: "#select-issue",
            list: ".listbox.issue",
            options: ".option", // qualified within `list`
            openClass: "open",
            activeClass: "active",
            selectedClass: "selected",
            useLiveRegion: false,
            multiselect: false,
            noResultsText: null,
            selectionValue: selecteds => selecteds.map(s => s.innerText.trim()).join(", "),
            // eslint-disable-next-line max-len
            optionValue: "underline", // wrap the matched portion of the option (if applicable) in a span with class "underline"
            filter: "contains" // 'starts-with', 'equals', or funk
        });

        // select first option on load
        window.addEventListener("load", () => {
            comboboYear.goTo(comboboYear.getOptIndex() + 0).select();
            comboboIssue.goTo(comboboIssue.getOptIndex() + 0).select();						
        });
        let filterByMagazine = () => {
            var select_year = comboboYear.input.value;
            var selectdt = $('#select-year').attr('data-value');
            if (selectdt && selectdt != 0) {
                select_year = selectdt;
                comboboYear.input.value = selectdt;
                $('#select-year').removeAttr('data-value');
            }
            var m_id = 0;
            var pid = $('.choose-issue').attr('data-id');
            if (pid && pid != 0) {
                m_id = pid;
            }
            let request = new XMLHttpRequest();
            request.open("POST", window.sage.ajax_url);
            request.onreadystatechange = function() {
                if (this.readyState === 4 && this.status === 200) {
                    var content = document.querySelectorAll(".choose-issue");
                    if (content.length) {
                        content[0].innerHTML = this.responseText;
                        getMagazineInfo();
                    }
                }
                chooseMagazine();
            };
            var data = new FormData();
            data.append('action', 'get_all_magazine');
            data.append('select_year', select_year);
            data.append('m_id', m_id);
            request.send(data);
						
						$('#js-issue').text('Select');						
        }
        let getMagazineInfo = () => {
            let issueBtns = document.querySelectorAll(".choose-issue .indiv-issue > button");
            issueBtns.forEach(btn => {
                if (btn.classList.contains('active') && btn.hasAttribute("data-post_id")) {
                    var m_id = btn.dataset.post_id;
                    var pid = $('.choose-issue').attr('data-id');
                    if (pid && pid != 0) {
                        m_id = pid;
                        $('.choose-issue').removeAttr('data-id');
                    }

                    if (typeof m_id != "undefined" && m_id != '') {
                        let request = new XMLHttpRequest();
                        request.open("POST", window.sage.ajax_url);
                        request.onreadystatechange = function() {
                            if (this.readyState === 4 && this.status === 200) {
                                var content = document.querySelectorAll(".issue-info");
                                if (content.length) {
                                    content[0].innerHTML = this.responseText;
                                }
                            }
                        };
                        var data = new FormData();
                        data.append('action', 'get_magazine_info');
                        data.append('nonce', window.sage.nonce);
                        data.append('m_id', m_id);
                        request.send(data);
                    }
                }
            });

        }
        let chooseMagazine = () => {
            let issueBtns = document.querySelectorAll(".choose-issue .indiv-issue > button");
            issueBtns.forEach(issueBtn => {
                issueBtn.addEventListener("click", e => {
                    issueBtns.forEach(btn => {
                        if (btn.classList.contains('active')) {
                            btn.classList.remove("active");
                        }
                    });
                    issueBtn.classList.add('active');	
										
										var dataTitle = issueBtn.getAttribute('data-title');											
										$('#js-issue').text(dataTitle);
										$('#choose-issue').removeClass('choose-issue-show');
										$('#js-issue').removeClass('activity');
										
                    getMagazineInfo();
                });
            });
        };
        setTimeout(function() {
            chooseMagazine();
        }, 1000)
        comboboYear.addEventListener("selection", e => {
            setTimeout(function() {
                filterByMagazine();
            }, 10)

        });
				
				$('#js-issue').on('click',function(){
					$('#choose-issue').addClass('choose-issue-show');
					$(this).addClass('activity');
					
					return false;
				})
							
    },
    finalize() {
        // JavaScript to be fired on the home page, after the init JS
    }
};