export default {
  init() {
    window.addEventListener( "load", ()=> {
      rearrangeImg();
    } );

    window.addEventListener( "resize", e => {
      setTimeout( () => {
        rearrangeImg();
      }, 200 );
    } );

    // media queries
    let mobile = window.matchMedia( "(max-width: 1199px)" );
    
    let rearrangeImg = () => {
      let bannerImg = document.querySelector( ".banner.about .image" );

      let bannerTxt = document.querySelector( ".banner.about .text" );
      if ( mobile.matches ) {
        // origElement.parentNode.insertBefore(movedElement, origElement)
        bannerTxt.parentNode.insertBefore( bannerImg, bannerTxt );
      }
      else {
        // origElement.parentNode.insertBefore(movedElement, origElement)
        bannerImg.parentNode.insertBefore( bannerTxt, bannerImg );
      }
    };
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};

