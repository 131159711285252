import ClipboardJS from "clipboard";

export default {
  init() {
    let faq = () => {
      let questionToggle = document.querySelectorAll( ".questions .question-indiv button.header" );
      let shareQuestion = document.querySelectorAll( ".questions .question-indiv .content .share button" );
      let firstQuestions = document.querySelectorAll( ".questions" );
      
      // check if using anchor link
      if ( window.location.hash ) {
        let hashId = ( window.location.hash ).replace( "#", "" );
        
        // open question linked to
        questionToggle.forEach( ( toggle=> {
          let toggleId = toggle.parentElement.previousElementSibling.id;
          let toggleHeader = toggle.parentElement;
          
          if ( toggleId === hashId ) {
            let firstQuestion = questionToggle[0].parentElement;
            // shut all first questions
            firstQuestions.forEach( question=> {
              let firstQ = question.querySelector( ".question-indiv" );
              firstQ.classList.remove( "open" );
            } );
            firstQuestion.classList.remove( "open" );
            toggleHeader.classList.add( "open" );
          }
        } ) );
      }
      else {
        // if no anchor link, open the first question by default
        
      }

      questionToggle.forEach( toggle=> {
        toggle.addEventListener( "click", ()=> {
          toggle.parentElement.classList.toggle( "open" );
        } );
      } );

      shareQuestion.forEach( shareLink=> {
        // add tooltip on hover
        shareLink.addEventListener( "mouseenter", e=> {
          let currentQuestion = e.currentTarget;
          let clickToCopy = currentQuestion.nextElementSibling;
          clickToCopy.classList.add( "visible" );
        } );
        // remove tooltip when done hovering
        shareLink.addEventListener( "mouseleave", e=> {
          let currentQuestion = e.currentTarget;
          let clickToCopy = currentQuestion.nextElementSibling;
          clickToCopy.classList.remove( "visible" );
        } );
        // copy link
        shareLink.addEventListener( "click", e=> {
          e.preventDefault;
          
          let currentQuestion = e.currentTarget;
          let clickToCopy = currentQuestion.nextElementSibling;
          clickToCopy.classList.remove( "visible" );
    
          /**
            * execCommand is listed as depreciated with no indication of alternative,
            * so using ClipboardJS in the meantime
            * @param data-clipboard-text
            * @uses ClipboardJS
            * @link https://github.com/zenorocha/clipboard.js
            */
          // new ClipboardJS(shareLink);
          
          // copy the link
          // eslint-disable-next-line max-len
          // let anchorId = currentQuestion.parentElement.parentElement.parentElement.previousElementSibling.id;
          // let anchorLink = `${window.location.origin}${window.location.pathname}#${anchorId}`;
          //
          // let tempInput = document.createElement( "input" );
          // tempInput.type = "text";
          // tempInput.value = anchorLink;
          //
          // document.body.appendChild( tempInput );
          //
          // tempInput.select();
          // document.execCommand( "Copy" );
          //
          // document.body.removeChild( tempInput );

          // show the tooltip telling user link copied
          let linkCopied = clickToCopy.nextElementSibling;
          linkCopied.classList.add( "visible" );
          // remove tooltip after 2 seconds
          setTimeout( () => {
            linkCopied.classList.remove( "visible" );
          }, 2000 );
        } );
      } );
    };
    faq();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
