import Swiper, { Navigation, Pagination } from "swiper";

export default {
  init() {
    // media queries
    let mobile = window.matchMedia( "(max-width: 1199px)" );

    // Banner slider
    let newsEventsSlider = ()=> {
      // if history module exist
      let bannerSlider = document.querySelector( ".banner.news-events .swiper-container" );
      let postTitle = document.querySelectorAll( ".swiper-container .swiper-slide .text h1" );
     
      // add different class to each slider so you can have multiple
       
      let defaultImg = document.querySelector( ".banner.news-events .image img" );
          
      const swiper = new Swiper( bannerSlider, {
        // Optional parameters
        direction:    "horizontal",
        loop:         false,
        spaceBetween: 100,
        
        // If we need pagination
        pagination: {
          el:           ".swiper-pagination",
          clickable:    true,
          // custom bullets
          renderBullet: function( index, className ) {
            if ( mobile.matches ) {
              // eslint-disable-next-line max-len
              return `<button class="${className} post-${( index + 1 )} copy-bold">${( index + 1 )}</button>`;
            }
            else {
              // eslint-disable-next-line max-len
              return `<button class="${className} post-${( index + 1 )} copy-bold">${postTitle[index].innerHTML}</button>`;
            }
          }
        },
        
        // Navigation arrows
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev"
        },
        
        // And if we need scrollbar
        scrollbar: {
          el: ".swiper-scrollbar"
        }
      } );
      
      // when slide changes, add correct image in
      swiper.on( "slideChange", ()=> {
        let indivSlides = bannerSlider.querySelectorAll( ".swiper-slide" );
        let currentSlide = indivSlides[swiper.activeIndex];
            
        let currentImg = currentSlide.getAttribute( "data-img" );
        let currentAlt = currentSlide.getAttribute( "data-alt" );
				let currentClass = currentSlide.getAttribute( "data-class" );
        defaultImg.classList.add( "fade-out" );
				defaultImg.classList.remove( "show-img", "hide-img"  );
        setTimeout( () => {
          defaultImg.src = currentImg;
          defaultImg.alt = currentAlt;
          defaultImg.classList.add( "fade-in" );
					defaultImg.classList.add( currentClass );
        }, 300 );
        setTimeout( () => {
          defaultImg.classList.remove( "fade-out", "fade-in" );
        }, 600 );
      } );

      let paginationButtons = document.querySelectorAll( ".swiper-pagination-bullet" );
      // when swiper resizes, change bullets to numbers or title
      swiper.on( "resize", ()=> {
        for ( let i = 0; i < paginationButtons.length; i++ ) {
          if ( mobile.matches ) {
            paginationButtons[i].innerHTML = i + 1;
          }
          else {
            paginationButtons[i].innerHTML = postTitle[i].innerHTML;
          }
        }
      } );
    };
      
    newsEventsSlider();
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
