
export default {
  init() {
    window.addEventListener( "load", ()=> {
      rearrangeImg();
    } );

    window.addEventListener( "resize", e => {
      setTimeout( () => {
        rearrangeImg();
      }, 200 );
    } );

    // switch image / text on mobile
    let mobile = window.matchMedia( "(max-width: 1199px)" );
    console.log( "test" );
    
    let rearrangeImg = () => {
      let left = document.querySelector( "#beyond-certification .left" );

      let right = document.querySelector( "#beyond-certification .right" );
      if ( mobile.matches ) {
        console.log( "test" );
        // origElement.parentNode.insertBefore(movedElement, origElement)
        left.parentNode.insertBefore( right, left );
      }
      else {
        // origElement.parentNode.insertBefore(movedElement, origElement)
        right.parentNode.insertBefore( left, right );
      }
    };
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
