import Swiper, { Navigation, Pagination } from "swiper";
export default {
  init() {
    let form = document.getElementById("programs-list");
    let listPrograms = () => {
        setTimeout(function() {
            let request = new XMLHttpRequest();
            request.responseType = 'json';
            request.open("POST", window.sage.ajax_url);
            request.onreadystatechange = function() {
                if (this.readyState === 4 && this.status === 200) {
                  var template = wp.template('program-item');
                  $('.programs-grid').html(template(this.response));
                    var template2 = wp.template('pagination');
                    $('.pagination-block').html(template2(this.response));
                }
            };
            var data = new FormData(form);
            request.send(data);
        }, 10)
    }
    if(form.length){
      listPrograms();
    }
    $(document).on("click", '.pagination-block .no-bullets li a', function(event) {
      event.preventDefault();
      var paged = $(this).html();
      form.querySelector('#form_paged').value=paged;
      listPrograms();
  });
  $(document).on("click", '.pagination-button-next', function(event) {
      event.preventDefault();
      var paged = form.querySelector('#form_paged').value;
      if (paged) {
          paged = parseInt(paged) + 1;
      }
      form.querySelector('#form_paged').value = paged;
      listPrograms();
  });
  $(document).on("click", '.pagination-button-prev', function(event) {
      event.preventDefault();
      var paged = form.querySelector('#form_paged').value;
      if (paged) {
          paged = parseInt(paged) - 1;
      }
      form.querySelector('#form_paged').value = paged;
      listPrograms();
  });
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
