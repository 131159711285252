import Swiper, { Navigation, Pagination } from "swiper";
import Combobo from "combobo";
export default {
  init() {
   
  },
  finalize() {
    // JavaScript to be fired on the home page, after the init JS
  }
};
